import { USER_AUTH, SYS_BUTTON_AUTH, USER_INFO } from "@/store/mutation-types"
import Vue from "vue"

const hasPermission = {
  install(Vue, options) {
    //console.log(options);
    Vue.directive('has', {
      inserted: (el, binding, vnode) => {
        console.log("页面权限控制----", { el, binding, vnode });
        //console.time()
        //节点权限处理，如果命中则不进行全局权限处理
        if (!filterNodePermission(el, binding, vnode)) {
          filterGlobalPermission(el, binding, vnode);
        }
        //console.timeEnd() //计时结束并输出时长
      }
    });
  }
};

export function checkPermission(permission,list) {
  let permissionList = [];
  let permissions = [];
  let authList = list;
  for (let auth of authList) {
    if (auth.type != '2') {
      permissionList.push(auth);
    }
  }

  for (let item of permissionList) {
    //权限策略1显示2禁用
    if (item.type != '2') {
      if (item.action) {
        if (item.action.includes(",")) {
          let split = item.action.split(",")
          for (let i = 0; i < split.length; i++) {
            if (!split[i] || split[i].length == 0) {
              continue;
            }
            permissions.push(split[i]);
          }
        } else {
          permissions.push(item.action);
        }
      }
    }
  }
  if (!permissions.includes(permission)) {
    return false;
  }
  return true;
}

/**
 * 流程节点权限控制
 */
export function filterNodePermission(el, binding, vnode) {
  let permissionList = [];
  try {
    let obj = vnode.context.$props.formData;
    if (obj) {
      let bpmList = obj.permissionList;
      for (let bpm of bpmList) {
        if (bpm.type != '2') {
          permissionList.push(bpm);
        }
      }
    } else {
      return false;
    }
  } catch (e) {
    //console.log("页面权限异常----", e);
  }
  if (permissionList === null || permissionList === "" || permissionList === undefined || permissionList.length <= 0) {
    //el.parentNode.removeChild(el)
    return false;
  }

  console.log("流程节点页面权限--NODE--");
  let permissions = [];
  for (let item of permissionList) {
    if (item.type != '2') {
      permissions.push(item.action);
    }
  }
  //console.log("页面权限----"+permissions);
  //console.log("页面权限----"+binding.value);
  if (!permissions.includes(binding.value)) {
    //el.parentNode.removeChild(el)
    return false;
  } else {
    for (let item2 of permissionList) {
      if (binding.value === item2.action) {
        return true;
      }
    }
  }
  return false;
}

/**
 * 全局权限控制
 */
export function filterGlobalPermission(el, binding, vnode) {
  console.log("全局页面权限--Global--");

  let permissionList = [];
  let allPermissionList = [];

  /*   let userInfo = Vue.ls.get(USER_INFO);
    console.log({ userInfo });
    if (userInfo?.onlyTool && window.location.pathname != "/tool") {
      console.log('跳转工作台页面', { userInfo });
      window.location.href = "/tool"
    } */
  let authList = JSON.parse(sessionStorage.getItem(USER_AUTH) || "[]");
  for (let auth of authList) {
    if (auth.type != '2') {
      permissionList.push(auth);
    }
  }
  //console.log("页面权限--Global--",sessionStorage.getItem(SYS_BUTTON_AUTH));
  let allAuthList = JSON.parse(sessionStorage.getItem(SYS_BUTTON_AUTH) || "[]");
  for (let gauth of allAuthList) {
    if (gauth.type != '2') {
      allPermissionList.push(gauth);
    }
  }
  //设置全局配置是否有命中
  let invalidFlag = false;//无效命中
  if (allPermissionList != null && allPermissionList != "" && allPermissionList != undefined && allPermissionList.length > 0) {
    for (let itemG of allPermissionList) {
      if (binding.value === itemG.action) {
        if (itemG.status == '0') {
          invalidFlag = true;
          break;
        }
      }
    }
  }
  if (invalidFlag) {
    return;
  }
  if (permissionList === null || permissionList === "" || permissionList === undefined || permissionList.length <= 0) {
    el.parentNode.removeChild(el);
    return;
  }
  let permissions = [];
  for (let item of permissionList) {
    //权限策略1显示2禁用
    if (item.type != '2') {
      //update--begin--autor:wangshuai-----date:20200729------for：按钮权限，授权标识的提示信息是多个用逗号分隔逻辑处理 gitee#I1OUGU-------
      if (item.action) {
        if (item.action.includes(",")) {
          let split = item.action.split(",")
          for (let i = 0; i < split.length; i++) {
            if (!split[i] || split[i].length == 0) {
              continue;
            }
            permissions.push(split[i]);
          }
        } else {
          permissions.push(item.action);
        }
      }
      //update--end--autor:wangshuai-----date:20200729------for：按钮权限，授权标识的提示信息是多个用逗号分隔逻辑处理 gitee#I1OUGU------
    }
  }
  if (!permissions.includes(binding.value) && binding.value != '') {
    el.parentNode && el.parentNode.removeChild(el);
  }
}

export default hasPermission;
